<script>
import { Bar } from 'vue-chartjs'
import moment from 'moment'
export default {
	extends: Bar,
	computed: { 
		meses_anteriores() {  
			return this.$store.state.reportes.meses_anteriores
		},
		loading() {  
			return this.$store.state.reportes.loading
		},
	},
	watch: {
		meses_anteriores() {
			console.log('wacth chart')
			this.setChart()
		},
		loading() {
			console.log('wacth chart')
			this.setChart()
		},
	},
	data() {
		return {
			per_page: 10,
		}
	},
	mounted() {
		this.setChart()
	},
	methods: {
		setChart() {	

			console.log('setChart')

			if (typeof this.meses_anteriores == 'undefined') {
				return 
			}

			let labels = []

			let total_vendido = []
			let deuda_clientes = []
			let ingresos_brutos = []
			let ingresos_netos = []
			let rentabilidad = []
			let gastos = []
			let total_vendido_a_cuenta_corriente = []
			let total_pagado_a_cuenta_corriente = []
			let total_pagado_mostrador = []
			
			this.meses_anteriores.forEach(meses_anteriores => {
				labels.push(meses_anteriores.fecha)
				total_vendido.push(meses_anteriores.total_vendido)	
				deuda_clientes.push(meses_anteriores.deuda_clientes)	
				ingresos_brutos.push(meses_anteriores.total_vendido)
				ingresos_netos.push(meses_anteriores.ingresos_netos)
				rentabilidad.push(meses_anteriores.rentabilidad)
				gastos.push(meses_anteriores.total_gastos)
				total_vendido_a_cuenta_corriente.push(meses_anteriores.total_vendido_a_cuenta_corriente)	
				total_pagado_a_cuenta_corriente.push(meses_anteriores.total_pagado_a_cuenta_corriente)	
				total_pagado_mostrador.push(meses_anteriores.total_pagado_mostrador)	
			})

			let datasets = [
				{
					label: 'Total vendido',
					backgroundColor: '#007bff',
					data: total_vendido,
				},
				{
					label: 'Deuda clientes',
					backgroundColor: '#4f4f4f',
					data: deuda_clientes,
				},
				{
					label: 'Vendido a C/C',
					backgroundColor: '#dc3545',
					data: total_vendido_a_cuenta_corriente,
				},
				{
					label: 'Ingresos por C/C',
					backgroundColor: '#28a745',
					data: total_pagado_a_cuenta_corriente,
				},
				{
					label: 'Ingresos mostrador',
					backgroundColor: '#ffc107',
					data: total_pagado_mostrador,
				},
				{
					label: 'Ingresos Brutos',
					backgroundColor: '#9966FF',
					// backgroundColor: '#383838',
					data: ingresos_brutos,
				},
				{
					label: 'Ingresos Netos',
					backgroundColor: '#ff8b00',
					data: ingresos_netos,
				},
				{
					label: 'Gastos',
					backgroundColor: '#BA68C8',
					data: gastos,
				},
				{
					label: 'Rentabilidad',
					backgroundColor: '#00a6b7',
					data: rentabilidad,
				},
			]

			let that = this
			this.renderChart({
				labels: labels,
				datasets: datasets,
			}, {
				plugins: {
					datalabels: { 
						anchor: 'end',
						align: 'top',
						color: '#333',
						font: {
							weight: 'bold',
						},
						formatter: function(value, context) {
							return null
						},
					},
				},
				maintainAspectRatio: false,
				onClick: function (event, elements, chart) {
					// let provider = providers[elements[0]._index]
					// that.setSelectedProvider(provider)
				},
				tooltips: {
					callbacks: {
						// label: function(tooltipItem, data) {
						// 	let price = Math.round(tooltipItem.yLabel)

						// 	if (price != 0) {
						// 		return that.price(price)
						// 	}
						// 	return null
						// }
						label: function (tooltipItem, data) {
							const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
							const value = tooltipItem.yLabel;
							return `${datasetLabel}: ${that.price(value)}`;
						},
					}
				}
			})
		},
		setSelectedProvider(provider) {
			this.$router.push({params: {sub_view: 'rendimiento-por-proveedor'}})
			this.setProviderArticles(provider)
		}
	},
}
</script>